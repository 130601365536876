<template>
  <div class="row_contain" style="padding-top: 46px">
    <van-nav-bar
      :fixed="fixed"
      title="创建订单"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="shoppingRow">
      <van-cell center title="填写地址或上传二维码">
        <template #right-icon>
          <van-switch
            :value="checked"
            @input="onInput"
            @delete="onDelete"
            size="24"
          />
        </template>
      </van-cell>
      <van-cell-group>
        <van-field
          v-model="order_code"
          label="订单号"
          placeholder="请输入订单号"
        />
      </van-cell-group>
      <van-cell-group v-if="checked">
        <van-field
          v-model="lastname"
          label="收件人-姓"
          placeholder="请输入收件人-姓"
        />
        <van-field
          v-model="name"
          label="收件人-名"
          placeholder="请输入收件人-名"
        />
        <van-field v-model="jinc" label="敬称" readonly />
        <van-field v-model="code" label="邮编" placeholder="请输入邮编" />
        <van-field
          v-model="province"
          label="住所1都道府县"
          placeholder="请输入都道府县"
        />
        <van-field
          v-model="city"
          label="住所2详细"
          placeholder="住所2详细地址"
        />
        <van-field
          v-model="address"
          label="住所3"
          placeholder="住所3(非必填)"
        />
        <van-field
          v-model="address2"
          label="住所4"
          placeholder="住所4(非必填)"
        />
        <van-field
          v-model="goods_content"
          label="产品类别"
          placeholder="请输入产品类别"
        />
      </van-cell-group>
      <van-cell-group v-else>
        <van-uploader
          :after-read="afterRead"
          v-model="fileList"
          @delete="onDelete"
          :max-count="1"
        />
      </van-cell-group>
      <div v-for="(item, key) in list.goods" :key="key">
        <van-card
          :num="item.num"
          currency="￥"
          :price="item.total_price"
          :desc="item.des"
          :title="item.title"
          :thumb="item.pic"
        >
          <template #tags>
            <van-tag plain type="danger">属性：{{ item.tag_name }}</van-tag>
          </template>
        </van-card>
      </div>
      <van-cell-group>
        <van-cell
          title="总价"
          :value="'￥' + list.total_price"
          value-class="money"
        />
      </van-cell-group>
      <van-cell-group>
        <van-field v-model="node" label="备注" placeholder="备注信息(非必填)" />
      </van-cell-group>
      <van-submit-bar
        :price="list.total_price * 100"
        button-text="确认支付"
        @submit="onSubmit"
      />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    id: {},
    type: {}
  },
  data () {
    return {
      checked: true,
      fileList: [],//图片
      goods_content: '',
      jinc: '様',
      order_code: '',
      lastname: '',
      province: '',
      city: '',
      address2: '',
      node: '',
      code: '',
      name: '',
      address: '',
      pic: '',
      list: {
        address: [],
        total_price: 0
      },
      fixed: true,
      clickable: true
    }
  },
  created () {
    console.log(this.id, this.type)
    this.load();

  },
  methods: {
    onInput (checked) {
      if (this.checked) {
        this.$Dialog.confirm({
          title: '提醒',
          message: '是否切换上传发货二维码？',
        }).then(() => {
          this.checked = checked;
        });
      } else {
        this.$Dialog.confirm({
          title: '提醒',
          message: '是否切换填写发货地址？',
        }).then(() => {
          this.checked = checked;
          this.fileList = [];
        });
      }

    },
    onDelete (file) {
      console.log('onDelete', file);
      this.fileList = [];

    },
    async afterRead (file) {
      // 此时可以自行将文件上传至服务器
      console.log('123', file);
      var formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
      formData.append('Filedata', file.file);
      //file/uploadFiles 
      //   let value = this.$qs.stringify({
      //     Filedata: formData
      //   })

      let res = await this.$http.post('file/uploadFiles', formData);

      if (res) {

        this.pic = res.data.data
      }
    },
    load () {
      this.$Toast.loading({ forbidClick: true });
      this.$http.get('order/get_order_goods', {
        params: {
          user_id: this.$store.state.login.userInfo.user_id,
          type: this.type,
          carts_id: this.id
        }
      }).then(res => {
        this.list = res.data.data;
        this.$Toast.clear();

      })
    },
    async onSubmit () {

      //order/add_order
      this.$Toast.loading({ forbidClick: true });

      let res = await this.$http.get('order/add_order', {
        params: {
          user_id: this.$store.state.login.userInfo.user_id,
          goods_content: this.goods_content,
          order_code: this.order_code,
          type: this.type,
          code: this.code,
          lastname: this.lastname,
          province: this.province,
          city: this.city,
          name: this.name,
          address: this.address,
          carts_id: this.id,
          address2: this.address2,
          node: this.node,
          pic: this.pic
        }
      });

      if (res.data.error_code == 0) {
        this.$Notify(res.data.error_msg)
        this.$router.replace('/order/delivery')
      } else {
        this.$router.replace('/order/payment')
      }
      this.$Toast.clear();
      this.$store.dispatch('CountAsync');

    },
    onClickLeft () {
      this.$router.back()
    },

  }
}
</script>

<style lang="less" scoped>
.van-uploader {
  margin: 10px;
  &__upload {
    width: 120px !important;
    height: 120px !important;
  }
}

.van-card__header {
  padding: 10px 0;
}
.van-submit-bar {
  bottom: 50px;
}
.money {
  font-size: 18px;
  color: red;
}
.van-card__thumb {
  width: 120px;
  height: 120px;
}
.van-card__content {
  padding-right: 10px;
}
.van-card__footer {
  padding: 5px;
  border-top: 1px dashed #c9c9c9;
}
.van-card {
  background: #fff;
  border-bottom: 1px solid #ccc;
  padding: 0;
  padding-left: 10px;
}
</style>